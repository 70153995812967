import styled from "styled-components"

import { IconButton, InputBase } from "@material-ui/core"

import { backgroundColor, divider } from "src/ui/colors"
import MinusIcon from "src/ui/icons/minus.svg"
import PlusIcon from "src/ui/icons/plus.svg"
import { spacing } from "src/ui/spacing"

interface IMNumberInput {
  onBlur?: (s: number) => void
  onChange: (value: number) => void
  onMaxExceeded?: () => void
  onMinExceeded?: () => void
  value: number
  min?: number
  max?: number
  disabled?: boolean
  step?: number
  size?: "small" | "medium"
}

export function MNumberInput({
  onBlur,
  onChange,
  onMaxExceeded,
  onMinExceeded,
  value,
  min = Number.MIN_SAFE_INTEGER,
  max = Number.MAX_SAFE_INTEGER,
  size = "small",
  disabled,
  step = 1,
}: IMNumberInput) {
  function parseNumber(num: number) {
    if (num > max) {
      onMaxExceeded?.()
    }

    if (num > min) {
      onMinExceeded?.()
    }

    return clamp({ num, min, max })
  }

  function parseInputValue(v: string) {
    const parsedValue = Number.parseInt(v)

    if (Number.isNaN(parsedValue)) {
      return value
    }

    return parseNumber(parsedValue)
  }

  function handleInputBlur(s: string) {
    const parsedValue = parseInputValue(s)

    if (parsedValue === value) {
      return
    }

    onBlur?.(parsedValue)
  }

  function handleInputChange(val: string) {
    const parsedValue = parseInputValue(val)

    if (parsedValue === value) {
      return
    }

    onChange(parsedValue)
  }

  return (
    <Box>
      <LeftIcon
        onClick={() => onChange(parseNumber(value - step))}
        size={size}
        disabled={value <= min || disabled}
      >
        <MinusIcon width={18} />
      </LeftIcon>
      <Input
        type="number"
        value={value}
        inputProps={{
          style: {
            textAlign: "center",
            width: "4ch",
            minHeight: "1.8rem",
          },
        }}
        onChange={(e) => handleInputChange(e.target.value)}
        inputMode="numeric"
        disabled={disabled}
        onBlur={(e) => handleInputBlur(e?.target.value)}
      />
      <RightIcon
        onClick={() => onChange(parseNumber(value + step))}
        size={size}
        disabled={value >= max || disabled}
      >
        <PlusIcon width={18} />
      </RightIcon>
    </Box>
  )
}

function clamp({ num, min, max }: { num: number; min: number; max: number }) {
  return Math.min(Math.max(num, min), max)
}

const Box = styled.div`
  border: 1px solid ${divider};
  border-radius: 8px;
  max-width: max-content;
  background: ${backgroundColor};
`

const RightIcon = styled(IconButton)`
  margin-right: ${spacing.XS};
`

const LeftIcon = styled(IconButton)`
  margin-left: ${spacing.XS};
`

const Input = styled(InputBase)`
  /* Blink & Webkit */
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`
